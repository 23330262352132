<template>
  <div>
    <Breadcrumbs />
    <section class="product product-section">
      <div class="go-back-btn container mt-3">
        <b-link @click.native="$router.back()"
          ><b-icon icon="chevron-left"></b-icon>{{ $t("go_back") }}</b-link
        >
      </div>
      <div class="container" v-if="product">
        <b-row class="pb-md-40 product-details">
          <b-col col lg="8" md="12" cols="12">
            <ProductGallery
              v-if="product.media_gallery.length > 0"
              :product="product"
              @changeIsInWishList="UpdateIsInWishList"
              :images="product.media_gallery"
              :isInWishList="
                $store.getters['user/isProductInWishlist'](product.sku)
              "
            />
            <ProductGallery
              v-if="product.media_gallery.length == 0"
              :product="product"
              @changeIsInWishList="UpdateIsInWishList"
              :images="[product.image]"
              :isInWishList="
                $store.getters['user/isProductInWishlist'](product.sku)
              "
            />
          </b-col>
          <b-col col lg="4" md="12" cols="12">
            <div>
              <span class="product--name">{{ product.name }}</span>
            </div>

            <div class="rating-wrap">
              <div
                data-bv-show="rating_summary"
                :data-bv-product-id="product.sku"
              ></div>

              <!--<div class="display-rating">
                <Rating
                  :stars="product.rating_summary"
                  :noOfRatings="product.review_count"
                />
              </div>-->
              <div class="energy-class" v-if="energyClass">
                <span
                  :class="{
                    'energy-class-a':
                      energyClass.value[0].toUpperCase() === 'A',
                    'energy-class-b':
                      energyClass.value[0].toUpperCase() === 'B',
                    'energy-class-c':
                      energyClass.value[0].toUpperCase() === 'C',
                    'energy-class-d':
                      energyClass.value[0].toUpperCase() === 'D',
                    'energy-class-e':
                      energyClass.value[0].toUpperCase() === 'E',
                    'energy-class-f':
                      energyClass.value[0].toUpperCase() === 'F',
                    'energy-class-g':
                      energyClass.value[0].toUpperCase() === 'G',
                  }"
                  >{{ energyClass.value }}</span
                >
              </div>
            </div>

            <ProductParameters
              v-if="product.__typename != 'BundleProduct'"
              :isInWishList="
                $store.getters['user/isProductInWishlist'](product.sku)
              "
              @changeIsInWishList="UpdateIsInWishList"
            />

            <ProductParametersBundle
              v-if="product.__typename == 'BundleProduct'"
              :isInWishList="false"
            />

            <ul class="note in-detail">
              <cmsBlockSimple
                v-if="typeof UspContent == 'object'"
                :identifier="UspContent.identifier"
              />
            </ul>
          </b-col>
        </b-row>
        <div class="product-description py-5">
          <b-row class="">
            <b-col col lg="12">
              <DescriptionTab />
            </b-col>
          </b-row>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isServer } from "@storefront/core/helpers";
import { Logger } from "@storefront/core/lib/logger";

import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import ProductGallery from "@/base/core/components/products/product-detail/ProductGallery";
import ProductParameters from "@/base/core/components/products/product-detail/ProductParameters";
import ProductParametersBundle from "@/base/core/components/products/product-detail/ProductParametersBundle";
import DescriptionTab from "@/base/core/components/products/product-detail/DescriptionTab";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

export default {
  name: "ProductPage",
  components: {
    Breadcrumbs,
    ProductGallery,
    ProductParameters,
    ProductParametersBundle,
    DescriptionTab,
    cmsBlockSimple,
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      related: "product/getRelated",
    }),
    showDismissibleAlert() {
      return false;
    },
    productForm() {
      return this.$store.getters["forms/getProductForm"];
    },
    energyClass() {
      return this.product.information_attributes.find(
        (attr) => attr.label === "Energieklasse"
      );
    },
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    UspContent() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`]("pdp_usp");
    },
    getGTMItems() {
      let items = {
        item_name: this.product.name,
        item_id: this.product.sku,
        quantity: this.quantity,
        price: this.finalPrice,
      };
      if (this.finalPrice != this.regularPrice) {
        items["discount"] = this.regularPrice - this.finalPrice;
      }
      return items;
    },
    finalPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.final_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.final_price.value !=
          this.product.price_range.maximum_price.final_price.value
        ) {
          return (
            this.product.price_range.minimum_price.final_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.final_price.value
              .toFixed(2)
              .replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.final_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.final_price.value !=
        this.product.price_range.maximum_price.final_price.value
      ) {
        return (
          this.product.price_range.minimum_price.final_price.value
            .toFixed(2)
            .replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.final_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.final_price.value.toFixed(
        2
      );
    },
    regularPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.regular_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.regular_price.value !=
          this.product.price_range.maximum_price.regular_price.value
        ) {
          return (
            this.product.price_range.minimum_price.regular_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.regular_price.value
              .toFixed(2)
              .replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.regular_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.regular_price.value !=
        this.product.price_range.maximum_price.regular_price.value
      ) {
        return (
          this.product.price_range.minimum_price.regular_price.value
            .toFixed(2)
            .replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.regular_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.regular_price.value.toFixed(
        2
      );
    },
  },
  mounted() {
    Logger.debug("route", "product", this.$route)();

    if (this.$gtm.enabled()) {
      if (!this.$gtm.uaDisabled) {
        //GA event
        window.dataLayer.push({
          event: "eec.detail",
          ecommerce: {
            detail: {
              products: [
                {
                  name: this.product.name,
                  id: this.product.sku,
                },
              ],
            },
          },
        });
      }
      if (this.$gtm.ga4Enabled) {
        window.dataLayer.push({
          event: "view_item",
          ecommerce: {
            add: {
              currency: "EUR",
              value: this.finalPrice,
              items: [this.getGTMItems],
            },
          },
        });
      }
    }
    this.updateBreadcrumbs();
  },
  watch: {
    product() {
      if (!isServer) {
        if (this.$gtm.enabled()) {
          Logger.debug("Watch Product", "product.vue", "CALLED")();
          if (!this.$gtm.uaDisabled) {
            //GA event
            window.dataLayer.push({
              event: "eec.detail",
              ecommerce: {
                detail: {
                  products: [
                    {
                      name: this.product.name,
                      id: this.product.sku,
                    },
                  ],
                },
              },
            });
          }
          if (this.$gtm.ga4Enabled) {
            window.dataLayer.push({
              event: "view_item",
              ecommerce: {
                add: {
                  currency: "EUR",
                  value: this.finalPrice,
                  items: [this.getGTMItems],
                },
              },
            });
          }
        }
      }
    },
  },
  methods: {
    updateBreadcrumbs() {
      if (this.product) {
        const current = this.product.name;
        let breadcrumb = { current, routes: [] };
        if (this.$store.state.route.from.path != "/") {
          if (this.category) {
            let retval = this.product.categories.find(
              (i) => i.id === this.category.id
            );
            if (retval !== false) {
              const { breadcrumbs } = this.category;
              if (breadcrumbs != null) {
                breadcrumbs.sort((a, b) => {
                  if (a.category_level < b.category_level) return -1;
                  if (a.category_level > b.category_level) return 1;
                  return 0;
                });

                let path = "";
                breadcrumbs.forEach((element) => {
                  if (path.length > 0) {
                    path = path + "/";
                  }
                  path = path + element.category_url_key;
                  breadcrumb.routes.push({
                    name: element.category_name,
                    route_link: "/" + path,
                  });
                });
              }

              breadcrumb.routes.push({
                name: this.category.name,
                route_link: this.category.url_path,
              });
            }
          }
        }
        this.$store.commit("breadcrumbs/set", breadcrumb);
      }
    },

    UpdateIsInWishList() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        if (
          this.$store.getters["user/isProductInWishlist"](this.product.sku) ==
          true
        ) {
          this.$store.dispatch("user/setProductWishlistStatus", {
            sku: this.product.sku,
            parentSku: null,
          });
        } else {
          if (this.product.__typename == "SimpleProduct") {
            this.$store.dispatch("user/setProductWishlistStatus", {
              sku: this.product.sku,
              parentSku: null,
            });
            if (this.$gtm.enabled()) {
              if (typeof window.fbq === "function") {
                window.fbq("track", "AddToWishlist");
              }
            }
          } else {
            const retval = this.$store.getters["product/getCurrentChildSku"];
            if (retval == null) {
              const msg = {
                type: "danger",
                title: this.$t("wishlist error"),
                text: this.$t("wishlist_select_options"),
              };
              this.$store.dispatch("messages/sendMessage", { message: msg });
              //this.$router.push("/" + this.product.url_key);
            } else {
              Logger.debug(
                "ChildSku",
                "Product.vue",
                this.$store.getters["product/getCurrentChildSku"]
              )();
              Logger.debug("Sku", "Product.vue", this.product.sku)();
              this.$store.dispatch("user/setProductWishlistStatus", {
                sku: this.$store.getters["product/getCurrentChildSku"],
                parentSku: this.product.sku,
              });
              if (this.$gtm.enabled()) {
                if (typeof window.fbq === "function") {
                  window.fbq("track", "AddToWishlist");
                }
              }
            }
          }
        }
      } else {
        const msg = {
          type: "danger",
          title: this.$t("wishlist error"),
          text: this.$t("wishlist_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
  },
  data() {
    return {};
  },
  created() {},
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "title",
          content: this.product
            ? this.product.meta_title ?? this.product.name ?? ""
            : "",
        },
        {
          name: "keywords",
          content: this.product ? this.product.meta_keyword ?? "" : "",
        },
        {
          name: "description",
          content: this.product ? this.product.meta_description ?? "" : "",
        },
        { property: "og:type", content: "product" },
        {
          property: "og:title",
          content: this.product
            ? this.product.meta_title ?? this.product.name ?? ""
            : "",
        },
        {
          property: "og:image",
          content: this.product ? this.product.image.url ?? "" : "",
        },
        {
          property: "og:description",
          content: this.product ? this.product.meta_description ?? "" : "",
        },
        {
          property: "twitter:title",
          content: this.product
            ? this.product.meta_title ?? this.product.name ?? ""
            : "",
        },
        {
          property: "twitter:image",
          content: this.product ? this.product.image.url ?? "" : "",
        },
        {
          property: "twitter:description",
          content: this.product ? this.product.meta_description ?? "" : "",
        },
        {
          property: "product:price:amount",
          content: this.product
            ? this.product.price_range.minimum_price.final_price.value ?? ""
            : "",
        },
        {
          property: "product:price:currency",
          content: "EUR",
        },
      ],
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.product
        ? this.product.meta_title ?? this.product.name ?? ""
        : "",
    };
  },
};
</script>

<style lang="scss"></style>
