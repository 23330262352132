<template>
  <div>
    <div class="custom-nav-tabs">
      <ul class="nav nav-tabs">
        <li
          class="nav-item"
          v-if="product.description && product.description.html"
        >
          <b-link
            class="nav-link"
            :class="{ active: activeTab === 'product-details' }"
            @click="scrollToTab($event, 'product-details')"
            >{{ $t("product_dec") }}
            <span class="mob-icon"><i class="fa fa-chevron-right"></i></span
          ></b-link>
        </li>
        <li class="nav-item" v-if="product.short_description.html">
          <b-link
            class="nav-link"
            :class="{ active: activeTab === 'product-pros-cons' }"
            @click="scrollToTab($event, 'product-pros-cons')"
            >{{ $t("pros_cons") }}
            <span class="mob-icon"><i class="fa fa-chevron-right"></i></span
          ></b-link>
        </li>
        <li class="nav-item" v-if="product_specifications.length">
          <b-link
            class="nav-link"
            :class="{ active: activeTab === 'product-specification' }"
            @click="scrollToTab($event, 'product-specification')"
            >{{ $t("product_specifications") }}
            <span class="mob-icon"><i class="fa fa-chevron-right"></i></span
          ></b-link>
        </li>
        <li class="nav-item">
          <b-link
            class="nav-link"
            :class="{ active: activeTab === 'product-reviews' }"
            @click="scrollToTab($event, 'product-reviews')"
            >{{ $t("review")
            }}<span class="mob-icon"><i class="fa fa-chevron-right"></i></span>
          </b-link>
        </li>
        <li class="nav-item" v-if="related.length">
          <b-link
            class="nav-link"
            :class="{ active: activeTab === 'product-accessoires' }"
            @click="scrollToTab($event, 'product-accessoires')"
            >{{ $t("accessoires") }}
            <span class="mob-icon"><i class="fa fa-chevron-right"></i></span
          ></b-link>
        </li>
      </ul>
    </div>

    <div v-if="product.cashback_promotion" class="cashback-block">
      <div class="price">
        <span
          v-if="
            product.cashback_promotion.amount &&
            product.cashback_promotion.amount.value &&
            product.cashback_promotion.amount.value !== null
          "
        >
          &euro;{{ product.cashback_promotion.amount.value + ",-=" }}
        </span>
        <span v-if="product.cashback_promotion.name">
          {{ product.cashback_promotion.name }}
        </span>
      </div>
      <div
        class="info"
        v-if="product.cashback_promotion.description"
        v-html="product.cashback_promotion.description"
      />
    </div>

    <div class="product-description-tab">
      <b-row>
        <!-- :class="[related.length ? 'col-lg-7' : 'col-lg-12']" -->
        <b-col col-lg-12>
          <div
            v-html="product.description.html"
            id="product-details"
            class="product-description-text"
          ></div>
        </b-col>
        <b-col col lg="5" v-if="related.length > 0 && !mobileCheck">
          <div
            id="product-accessoires"
            class="product-gallery-block related-products"
          >
            <h2>{{ $t("product_detail_recommended_accessories") }}</h2>
            <ProductSlider
              v-if="related.length > 0"
              :productList="related"
              :showAddToCart="true"
              :productToShow="2"
              :rows="2"
              :infinite="false"
              :arrows="false"
              :dots="true"
            />
          </div>
        </b-col>
      </b-row>

      <div
        id="product-pros-cons"
        v-if="product.short_description.html"
        class="product-pros-cons"
      >
        <h4 class="specifications- product-page-title">
          {{ $t("product_pros-cons") }}
        </h4>

        <div v-html="product.short_description.html"></div>
      </div>
      <div id="product-specification" class="product-specification">
        <h4 class="specifications product-page-title">
          {{ $t("product_specifications") }}
        </h4>
        <div
          class="product-specification-list"
          v-if="product_specifications.length"
        >
          <div
            class="d-flex mb-2"
            v-for="(info, index) in product_specifications"
            :key="index"
          >
            <div v-if="info.value != 'false'" class="category d-flex">
              <div
                class="spec-tooltip mr-3 cursor-pointer"
                @click="info.tooltip != null && (activeTooltipId = index)"
              >
                <!-- v-b-toggle="'modal' + index" -->
                <div :class="{ disabled: !info.tooltip }">
                  <i class="fas fa-info-circle"></i>
                </div>
              </div>
              <b-modal
                :id="'modal' + index"
                :visible="activeTooltipId === index"
                @hidden="activeTooltipId = null"
                hide-header
                hide-footer
                centered
              >
                <div class="relative">
                  <div class="close-modal" @click="activeTooltipId = null">
                    <span><i class="fa fa-times"></i></span>
                  </div>
                  <div class="spectmodal-content fancy-scroll">
                    <div v-html="info.tooltip"></div>
                  </div>
                </div>
              </b-modal>
              <span>{{ info.label }}</span>
            </div>
            <div v-if="info.value != 'false'" class="detail ml-20 d-block">
              <span>{{ info.value }}</span>
            </div>
          </div>
          <div v-if="product_specifications.length < informationAttr.length">
            <b-link class="show-more" @click="showMoreSpecifications">
              {{ $t("show_more") }}
            </b-link>
          </div>
        </div>
      </div>

      <b-col col lg="5" v-if="related.length > 0 && mobileCheck">
        <div
          id="product-accessoires"
          class="product-gallery-block related-products"
        >
          <h2>{{ $t("product_detail_recommended_accessories") }}</h2>
          <ProductSlider
            v-if="related.length > 0"
            :productList="related"
            :showAddToCart="true"
            :productToShow="2"
            :rows="2"
            :infinite="false"
            :arrows="false"
            :dots="true"
          />
          <!-- <b-row>
            <b-col
              col
              lg="6"
              v-for="product of related"
              :key="product.sku"
              class="more-products--slide position-relative mb-3"
            >
              <ProductCard :product="product" />
            </b-col>
          </b-row>-->
          <!-- <MoreProductsCarousel :products="related" /> -->
        </div>
      </b-col>

      <!-- Review highlights - typically goes below the product description or image -->
      <div id="product-reviews" class="product-review">
        <div
          data-bv-show="review_highlights"
          :data-bv-product-id="product.sku"
        ></div>

        <!-- Reviews for productID123 here -->
        <div data-bv-show="reviews" :data-bv-product-id="product.sku"></div>
      </div>

      <!--<div id="product-reviews" class="product-review">
        <h4 class="reviews product-page-title">{{ $t("review") }}</h4>
        <b-link to="/add-review" class="btn btn-primary">
          {{ $t("write_review_button") }}
        </b-link>
        <div class="relevant-review-title">
          {{ $t("most_relevant_review") }}
        </div>
        <div>
          <div class="no-review" v-if="product.reviews.items.length == 0">
            {{ $t("no_reviews") }}
          </div>  -->
      <!-- class="product-reviews" -->
      <!--<div>
            <ProductReview
              :review="rev"
              v-for="(rev, index) of product.reviews.items"
              :key="index"
            />
          </div>
        </div>
      </div>
      <div class="review-actions" v-if="product.reviews.items.length > 0">
        <b-link to="/add-review" class="mr-5">
          {{ $t("write_review") }}
        </b-link>
        <b-link to="/reviews"> {{ $t("view_review") }} </b-link>
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import ProductCard from "@/base/core/components/products/category-product/ProductCard";
//import ProductReview from "@/base/core/components/products/product-detail/ProductReview";
import ProductSlider from "@/base/core/components/products/product-detail/ProductSlider.vue";
import { isMobileOnly } from "mobile-device-detect";

export default {
  name: "DescriptionTab",
  components: {
    // ProductCard,
    //ProductReview,
    ProductSlider,
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      related: "product/getRelated",
    }),
    mobileCheck() {
      if (isMobileOnly) {
        return true;
      } else {
        return false;
      }
    },
    informationAttr() {
      return this.product.information_attributes.filter(
        (item) =>
          !item.value.startsWith("X") &&
          !item.value.startsWith("xx") &&
          !item.value.startsWith("x,xx")
      );
    },
  },
  created() {
    if (this.product) {
      this.product_specifications = this.informationAttr.slice(0, 10);
    }
  },
  data: () => ({
    activeTab: "product-details",
    product_specifications: [],
    activeTooltipId: null,
    description: {
      details:
        "De Trani is een winterjack van Rogelli die speciaal is ontworpen voor de winter. Inmiddels al aan de sterk verbeterde versie 3.0. Het jack is winddicht en waterafstotend zodat je optimaal kunt presteren in koude weersomstandigheden. Voor optimaal comfort zijn er softshell stukken aan de voorkant en isolerend fleece aan de binnenvoering aangebracht. Aan de achterzijde zijn 3 zakjes.",
      features: [
        "Winddicht en waterafstotend",
        " Race pasvorm",
        " Warmte isolerend fleece binnenvoering",
        " Elastische band aan de pols",
        " Softshell stukken op de voorkant",
        " Silicone antislipstrip in de taille",
        " Extra rug- en borstzak met ritssluiting",
        "D.T. PENSTAR stof",
        "Lange rits",
        "Reflecterende accenten voor extra zichtbaarheid",
      ],
      moreInfo: [
        { category: "Productgroep", info: "Fietsjack winter" },
        { category: "Introductie jaar", info: "2018" },
        { category: "Gebruikte materialen", info: "100% polyester" },
        {
          category: "Temperatuuradvies",
          info: "Vanaf temperaturen onder het vriespunt",
        },
      ],
    },
    reviews: {
      noOfReviews: 15,
    },
  }),

  methods: {
    scrollToTab(_, elementId) {
      this.activeTab = elementId;

      const element = document.getElementById(elementId);
      const offset = 60;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    showMoreSpecifications() {
      this.product_specifications = this.informationAttr;
    },
  },
};
</script>

<style lang="scss">
@import "@/base/scss/variables.scss";
</style>
