var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.currentProperties &&
    _vm.currentProperties.options &&
    _vm.currentProperties.options.every(function (option) { return option.value; })
  )?_c('div',{staticClass:"postalcode"},[_c('div',{staticClass:"title"},[_c('b-modal',{attrs:{"id":'modal',"visible":_vm.showServiceInfo === true,"hide-header":"","hide-footer":"","centered":""},on:{"hidden":function($event){_vm.showServiceInfo = null}}},[_c('div',{staticClass:"postalmodal"},[_c('div',{staticClass:"close-modal",on:{"click":function($event){return _vm.closeServiceModal()}}},[_c('span',[_c('i',{staticClass:"fa fa-times"})])]),(_vm.serviceInfoContent === null)?_c('div',{staticClass:"spectmodal-content fancy-scroll"},[_c('label',[_vm._v("Postcodecheck")]),_c('p',[_vm._v(" In onze eigen regio bezorgen wij met onze eigen bezorgdienst. Controleer je postcode om te zien of je gebruik kan maken van onze extra service. ")]),_c('div',{staticClass:"postal-input"},[_c('b-form-input',{attrs:{"id":"postalcode","type":"text","aria-describedby":"postalcode-feedback","placeholder":"1234AB"},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}}),_c('b-btn',{attrs:{"variant":"secondary"},on:{"click":_vm.verifyPostalcode}},[_vm._v("Controleer postcode")])],1),_c('div',{staticClass:"postal-validate-msg"},[(_vm.isPostalCodeValid == true)?_c('p',{staticClass:"valid"},[_vm._v(" Je kunt gebruik maken van onze extra services en deze zijn toegevoegd aan uw product. ")]):_vm._e(),(_vm.isPostalCodeValid == false)?_c('p',{staticClass:"not-valid"},[_vm._v(" De extra diensten zijn helaas niet beschikbaar in jouw regio. Wel wordt dit product tot aan de deur bezorgd en kan de oude retour worden genomen mits deze klaar staat. De levertijd buiten onze regio is 2-4 werkdagen. ")]):_vm._e()])]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(this.serviceInfoContent)}}),(_vm.showCloseButton)?_c('div',[_c('b-btn',{on:{"click":_vm.closeServiceModal}},[_vm._v("Doorgaan")])],1):_vm._e()])])],1),(
      _vm.currentProperties &&
      _vm.currentProperties.options &&
      _vm.currentProperties.options.length
    )?_c('div',{staticClass:"postal-custom-option"},[_c('div',[_c('b-form-group',_vm._l((_vm.currentProperties.options[0].value),function(option){return _c('b-form-checkbox',{key:option.option_type_id,attrs:{"value":option},model:{value:(_vm.selectedOptions),callback:function ($$v) {_vm.selectedOptions=$$v},expression:"selectedOptions"}},[_vm._v(_vm._s(option.title)+" + "+_vm._s(_vm.formatPrice(option.price))+" "),_c('div',{staticClass:"cursor-pointer fa-pull-right pl-2",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showServiceModalPopup(option.sku)}}},[_c('i',{staticClass:"fas fa-info-circle",attrs:{"data-sku":option.sku}})])])}),1)],1)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }