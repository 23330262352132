var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-gallery"},[_c('b-row',[_c('b-col',{attrs:{"col":"","lg":"2","md":"2","cols":"12"}},[_c('div',[_c('ClientOnly',[_c('Lightbox',{attrs:{"visible":_vm.visible,"imgs":_vm.lightBoxImages,"index":_vm.ligthBoxIndex},on:{"hide":_vm.handleHide}}),_c('VueSlickCarousel',_vm._b({staticClass:"carousel",style:({ height: '372px' })},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.images),function(image,idx){return _c('div',{key:idx,attrs:{"id":("slide " + idx)}},[_c('b-link',{staticClass:"slide-img-holder",on:{"click":function($event){return _vm.clickSlideGallery(idx)}}},[_c('div',{staticClass:"slide-img",style:({ backgroundImage: ("url(" + (image.url) + ")") })})])],1)}),0)],1),_c('div',{staticClass:"product-tag mobile-product-tag"},[(_vm.product.cashback_promotion)?_c('div',{staticClass:"tag"},[_c('span',{staticClass:"discount-txt"},[(
                  _vm.product.cashback_promotion.amount &&
                  _vm.product.cashback_promotion.amount.value &&
                  _vm.product.cashback_promotion.amount.value !== null
                )?_c('span',[_vm._v(" €"+_vm._s(_vm.product.cashback_promotion.amount.value + ",-=")+" ")]):_vm._e(),(_vm.product.cashback_promotion.name)?_c('span',[_vm._v(" "+_vm._s(_vm.product.cashback_promotion.name)+" ")]):_vm._e()])]):_vm._e(),(_vm.product.labels && _vm.product.labels.length)?_c('div',{staticClass:"product-tag-wrap"},_vm._l((_vm.product.labels),function(label,idx){return _c('div',{key:idx,staticClass:"product-tag-single"},[(label.product.type === 'image')?_c('div',{staticClass:"img-label"},[_c('div',{staticClass:"tag-label",class:label.product.position,style:("background-image: url('" +
                    label.product.image_url +
                    "');" + (label.product.css))})]):_vm._e(),(label.product.type === 'text')?_c('div',{staticClass:"text-label"},[_c('div',{staticClass:"tag-label",class:label.product.position,style:(label.product.css)},[_vm._v(" "+_vm._s(label.product.text)+" ")])]):_vm._e()])}),0):_vm._e()])],1)]),_c('b-col',{attrs:{"col":"","lg":"10","md":"10","cols":"12"}},[_c('div',{staticClass:"big-img"},[_c('div',{staticClass:"product-tag desktop-product-tag"},[(_vm.product.cashback_promotion)?_c('div',{staticClass:"tag"},[_c('span',{staticClass:"discount-txt"},[(
                  _vm.product.cashback_promotion.amount &&
                  _vm.product.cashback_promotion.amount.value &&
                  _vm.product.cashback_promotion.amount.value !== null
                )?_c('span',[_vm._v(" €"+_vm._s(_vm.product.cashback_promotion.amount.value + ",-=")+" ")]):_vm._e(),(_vm.product.cashback_promotion.name)?_c('span',[_vm._v(" "+_vm._s(_vm.product.cashback_promotion.name)+" ")]):_vm._e()])]):_vm._e(),(_vm.product.labels && _vm.product.labels.length)?_c('div',{staticClass:"product-tag-wrap"},_vm._l((_vm.product.labels),function(label,idx){return _c('div',{key:idx,staticClass:"product-tag-single"},[(label.product.type === 'image')?_c('div',{staticClass:"img-label"},[_c('div',{staticClass:"tag-label",class:label.product.position,style:("width:" + (label.product.size) + "px;\n                    height:" + (label.product.size) + "px;\n                    background-image: url('" +
                    label.product.image_url +
                    "');\n                    " + (label.product.css))})]):_vm._e(),(label.product.type === 'text')?_c('div',{staticClass:"text-label"},[_c('div',{staticClass:"tag-label",class:label.product.position,style:(label.product.css)},[_vm._v(" "+_vm._s(label.product.text)+" ")])]):_vm._e()])}),0):_vm._e()]),_c('div',{staticClass:"selected-image-holder d-none d-md-block",on:{"click":function($event){$event.preventDefault();return _vm.showMultiple($event)}}},[_c('div',{staticClass:"selected-image",style:({ backgroundImage: ("url(" + _vm.selectedImage + ")") })})])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }