<template>
  <div>
    <b-modal
      id="modal-1"
      okVariant="success"
      okTitle="Doorgaan met afrekenen."
      cancelTitle="Doorgaan met winkelen."
      :title="$t('items_added_to_cart', { numberOf: quantity })"
      @ok="clickOk"
      @cancel="clickCancel"
    >
      <img :src="product.thumbnail.url" />
      <p class="my-4">{{ product.name }}</p>
      <p class="my-price">
        &euro;{{
          priceRange.maximum_price.final_price.value
            .toFixed(2)
            .replace(".", ",")
        }}
      </p>
    </b-modal>
    <b-modal id="modal-scrollable" scrollable :title="$t('sizechart')">
      <div class="text">
        <p>
          {{ $t("sizechart_warning") }}
        </p>
      </div>
      <b-table
        class="size-table"
        responsive
        :items="tableSize"
        :fields="fields"
      ></b-table>
    </b-modal>
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-between">
          <div
            v-if="
              priceRange.minimum_price.final_price.value !=
              priceRange.maximum_price.final_price.value
            "
            class="top-wrap"
          >
            <div class="d-flex align-items-end price-selection">
              <span class="currentPrice"
                >&euro;{{
                  priceRange.minimum_price.final_price.value
                    .toFixed(2)
                    .replace(".", ",")
                }}
                - &euro;{{
                  priceRange.maximum_price.final_price.value
                    .toFixed(2)
                    .replace(".", ",")
                }}</span
              >
              <span
                class="discount-comment"
                v-if="priceRange.minimum_price.discount.percent_off > 0"
                >{{ $t("discount") }}
                {{
                  priceRange.minimum_price.discount.percent_off
                    .toFixed(2)
                    .replace(".", ",")
                }}%</span
              >
            </div>
          </div>
          <div
            v-if="
              priceRange.minimum_price.final_price.value ==
              priceRange.maximum_price.final_price.value
            "
            class="top-wrap"
          >
            <span
              class="normal-price d-block"
              v-if="product.manufacturer_price.price.value > 0"
            >
              <span>{{ $t("advice_price") }}:</span>
              &euro;{{
                product.manufacturer_price.price.value
                  .toFixed(2)
                  .replace(".", ",")
              }}
            </span>
            <div class="d-flex align-items-end price-selection">
              <span class="currentPrice"
                >&euro;{{
                  priceRange.minimum_price.final_price.value
                    .toFixed(2)
                    .replace(".", ",")
                }}</span
              >
              <span
                class="discount-comment"
                v-if="priceRange.minimum_price.discount.percent_off > 0"
                >{{ $t("discount") }}
                {{
                  priceRange.minimum_price.discount.percent_off.toFixed(0)
                }}%</span
              >
            </div>
          </div>
          <div v-if="product.brand != null" class="product-logo">
            <b-link :href="`/` + product.brand.urlKey">
              <img :src="product.brand.logo" :alt="product.brand.title" />
            </b-link>
          </div>
        </div>
        <b-link v-b-modal.modal-scrollable class="size-info ml-10">{{
          $t("view_sizechart")
        }}</b-link>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div
          class="bundel-product"
          v-for="bundleProd of productBundles"
          :key="`bund` + bundleProd.option_id"
        >
          <div class="bundel-img">
            <img
              v-if="bundleProd.options[0].product != null"
              :src="bundleProd.options[0].product.image.url"
            />
          </div>
          <div class="product-name">
            {{ bundleProd.options[0].product.name }}
          </div>
          <div class="options-container">
            <div
              class="bundel-bottom"
              v-for="parameter of options[bundleProd.option_id]"
              :key="parameter.id"
            >
              <div class="size-selection">
                <div class="selection-category d-flex justify-content-between">
                  <span class="select-size">{{ parameter.label }}</span>
                  <div class="size-selection-radio">
                    <ul class="size-selection--available-sizes">
                      <li
                        v-for="opt of parameter.values"
                        :key="opt.value_index"
                      >
                        <label class="custom-radio-input">
                          <input
                            type="radio"
                            :id="`${bundleProd.option_id}-${parameter.attribute_code}-${opt.value_index}`"
                            :name="`${bundleProd.option_id}-${parameter.attribute_code}-select`"
                            :value="opt.value_index"
                            v-on:click="
                              processOption(
                                bundleProd.option_id,
                                parameter.index,
                                opt.value_index
                              )
                            "
                          />
                          <span
                            class="custom-radio-input--btn font-weight-normal"
                            >{{ opt.label }}</span
                          >
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex quantity-and-btn">
          <div class="quantity-input d-flex">
            <b-link class="step-down-btn" @click="quantityDown"></b-link>
            <input
              type="number"
              min="1"
              :value="quantity"
              name="quantity"
              ref="inputQuantity"
              disabled
            />
            <b-link class="step-up-btn" @click="quantityUp"></b-link>
          </div>
          <button
            v-on:click="addToCart"
            v-bind:disabled="btnDisable"
            class="btn btn-info product-parameter-submit-btn"
          >
            <i class="lnr lnr-cart"></i>{{ $t("add_to_cart") }}
          </button>
        </div>
        <span
          class="d-block text-info delivery-info mb-20"
          v-if="product.DeliveryTime != null"
          :style="'color: ' + product.DeliveryTime.color + '!important'"
        >
          {{ product.DeliveryTime.long }}</span
        >
        <div
          class="d-flex flex-column flex-md-row justify-content-between more-info"
          v-if="availability.length > 0"
        >
          <div class="available-stores mb-20 mb-md-0">
            <span class="d-block mb-3">Beschikbaarheid winkels:</span>
            <div
              v-for="(astore, index) in availability"
              :key="`astores-${index}`"
            >
              <span class="d-block">{{ astore.store.name }}</span>
            </div>
          </div>
          <div class="stock">
            <span class="d-block mb-3">&nbsp;</span>
            <div
              v-for="(astore, index) in availability"
              :key="`sstores-${index}`"
            >
              <span v-if="astore.has_stock == true" class="d-block text-info">{{
                $t("in_stock")
              }}</span>
              <span v-if="astore.has_stock != true" class="d-block">{{
                $t("not_in_stock")
              }}</span>
            </div>
          </div>
        </div>
        <div class="partners">
          <div v-if="typeof paymentPartners == 'object'">
            <ul class="payment-partners">
              <li v-for="(image, index) in paymentPartners.slides" :key="index">
                <img :src="image.media.url" :alt="image.title" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { mapGetters } from "vuex";
import { Logger } from "@storefront/core/lib/logger";
export default {
  name: "ProductParameters",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    parameters: { type: Object },
    isInWishList: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      productConfig: "product/getCurrentConfig",
      productBundles: "product/getCurrentBundle",
      options: "product/getCurrentBundleOptions",
    }),
    btnDisable() {
      return false;
      /*
      let reval = false;
      const bundle = this.$store.getters["product/getCurrentBundle"];
      Logger.debug("ProductParametersBundle", "btnDisable", bundle)();
      const parentSkus = this.$store.getters["product/getSelectedBundles"];
      const childSkus = this.$store.getters["product/getSelectedChildSkus"];
      bundle.forEach((bundleOption) => {
        Logger.debug(
          "ProductParametersBundle",
          "btnDisable",
          parentSkus[bundleOption.option_id]
        )();
        if (parentSkus[bundleOption.option_id] == null) {
          reval = true;
        }
        if (childSkus[bundleOption.option_id] == null) {
          reval = true;
        }
      });
      return reval;
      */
    },
    buttonClass() {
      if (this.btnDisable == true) {
        return "cursor: not-allowed;";
      } else {
        return "";
      }
    },
    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
    finalPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.final_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.final_price.value !=
          this.product.price_range.maximum.final_price.value
        ) {
          return (
            this.product.price_range.minimum_price.final_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.final_price.value.toFixed(2)
          );
        }
        return this.product.price_range.minimum_price.final_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.final_price.value !=
        this.product.price_range.maximum.final_price.value
      ) {
        return (
          this.product.price_range.minimum_price.final_price.value.toFixed(2) +
          " - " +
          this.product.price_range.maximum_price.final_price.value.toFixed(2)
        );
      }
      return this.product.price_range.minimum_price.final_price.value.toFixed(
        2
      );
    },
    regularPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.regular_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.regular_price.value !=
          this.product.price_range.maximum_price.regular_price.value
        ) {
          return (
            this.product.price_range.minimum_price.regular_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.regular_price.value
              .toFixed(2)
              .replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.regular_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.regular_price.value !=
        this.product.price_range.maximum_price.regular_price.value
      ) {
        return (
          this.product.price_range.minimum_price.regular_price.value
            .toFixed(2)
            .replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.regular_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.regular_price.value.toFixed(
        2
      );
    },
    priceRange() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range;
          }
        }
        return this.product.price_range;
      }
      return this.product.price_range;
    },
    availability() {
      if (this.product.__typename == "SimpleProduct") {
        return this.product.store_availability;
      }
      if (this.product.__typename == "ConfigurableProduct") {
        Logger.debug(
          "availability",
          "ProductParameters",
          this.product.__typename
        )();
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.store_availability;
          }
        }
      }

      return [];
    },
    getGTMItems() {
      let items = {
        item_name: this.product.name,
        item_id: this.product.sku,
        quantity: this.quantity,
        currency: "EUR",
        price: this.finalPrice,
      };
      if (this.finalPrice != this.regularPrice) {
        items["discount"] = this.regularPrice - this.finalPrice;
      }
      Logger.debug("getGTMItems", "productparameters", items)();
      return items;
    },
  },

  data: () => ({
    quantity: 1,
    popup: false,
    priceSize: "",
    tableSize: [
      {
        name: "Billy / BHE",
        sizeS: "49.5 - 54 cm",
        sizeM: "55 - 58 cm",
        sizeL: "n/a",
      },
      {
        name: "Metro / BHE -55",
        sizeS: "n/a",
        sizeM: "55 - 58 cm",
        sizeL: "56 - 61 cm",
      },
      {
        name: "Indra / BHE -56",
        sizeS: "n/a",
        sizeM: "55 - 58 cm",
        sizeL: "58 - 62 cm",
      },
    ],
    fields: [
      {
        key: "name",
        label: "",
      },
      {
        key: "sizeS",
        label: "S",
      },
      {
        key: "sizeM",
        label: "M",
      },
      {
        key: "sizeL",
        label: "L",
      },
    ],
  }),
  methods: {
    clickOk() {
      this.$router.push("/checkout");
    },
    clickCancel() {
      this.$router.push("/");
    },
    quantityUp() {
      this.quantity++;
    },
    quantityDown() {
      if (this.quantity > 1) this.quantity--;
    },
    processOption(bundle_id, index, value) {
      this.$store.commit("product/setBundleOptionValue", {
        bundle_id: bundle_id,
        index: index,
        value: value,
      });
    },
    async addToCart() {
      switch (this.product.__typename) {
        case "SimpleProduct": {
          const item =
            '{data: {sku: "' +
            this.product.sku +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              Logger.debug("Watch Product", "product.vue", "CALLED")();
              if (!this.$gtm.uaDisabled) {
                window.dataLayer.push({
                  event: "eec.add",
                  ecommerce: {
                    add: {
                      products: [
                        {
                          name: this.product.name,
                          id: this.product.sku,
                          quantity: this.quantity,
                        },
                      ],
                    },
                  },
                });
              }
              if (this.$gtm.ga4Enabled) {
                window.dataLayer.push({
                  event: "add_to_cart",
                  ecommerce: {
                    add: {
                      currency: "EUR",
                      value: this.finalPrice,
                      items: [this.getGTMItems],
                    },
                  },
                });
              }
            }
            this.$bvModal.show("modal-1");
          }
          break;
        }
        case "ConfigurableProduct": {
          const item =
            '{ parent_sku: "' +
            this.product.sku +
            '" data: {sku: "' +
            this.$store.getters["product/getCurrentChildSku"] +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              Logger.debug("Watch Product", "product.vue", "CALLED")();
              if (!this.$gtm.uaDisabled) {
                window.dataLayer.push({
                  event: "eec.add",
                  ecommerce: {
                    add: {
                      products: [
                        {
                          name: this.product.name,
                          id: this.product.sku,
                          quantity: this.quantity,
                        },
                      ],
                    },
                  },
                });
              }
              if (this.$gtm.ga4Enabled) {
                window.dataLayer.push({
                  event: "add_to_cart",
                  ecommerce: {
                    add: {
                      currency: "EUR",
                      value: this.product.final_price,
                      items: [this.getGTMItems],
                    },
                  },
                });
              }
            }

            this.$bvModal.show("modal-1");
          }
          break;
        }
        case "BundleProduct": {
          /*
          const item =
            '{ parent_sku: "' +
            this.product.sku +
            '" data: {sku: "' +
            this.$store.getters["product/getCurrentChildSku"] +
            '", quantity:' +
            this.quantity +
            "}}  ";
          */
          let item =
            "{ data: { sku: " +
            '"' +
            this.product.sku +
            '" quantity:' +
            this.quantity +
            "} " +
            "bundle_options: [";
          const bundles = this.$store.getters["product/getCurrentBundle"];
          const parentSkus = this.$store.getters["product/getSelectedBundles"];
          const childSkus = this.$store.getters["product/getSelectedChildSkus"];
          bundles.forEach((element) => {
            let option =
              " { id: " + element.option_id + " quantity: 1 value: [";
            option = option + "{ id: " + element.options[0].id;
            option =
              option + ' parent_sku: "' + parentSkus[element.option_id] + '"';
            option =
              option + ' variant_sku: "' + childSkus[element.option_id] + '"}';
            option = option + "]},";
            item = item + option;
          });
          item = item + "] }";

          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              Logger.debug("Watch Product", "product.vue", "CALLED")();
              window.dataLayer.push({
                event: "eec.add",
                ecommerce: {
                  add: {
                    products: [
                      {
                        name: this.product.name,
                        id: this.product.sku,
                        quantity: this.quantity,
                      },
                    ],
                  },
                },
              });
              if (this.$gtm.ga4Enabled) {
                window.dataLayer.push({
                  event: "add_to_cart",
                  ecommerce: {
                    add: {
                      currency: "EUR",
                      value: this.product.final_price,
                      items: [this.getGTMItems],
                    },
                  },
                });
              }
            }
            this.$bvModal.show("modal-1");
          }
          break;
        }

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
