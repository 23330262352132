<template>
  <div class="store-popup-wrap" v-if="availability && availability.length">
    <b-button
      :id="cardType + 'store' + product.sku"
      href="#"
      tabindex="0"
      class="pickup-store-btn"
    >
      <label v-if="totalInStockAvailability > 0">
        <img src="@/base/assets/icons/pin_grijs.svg" alt="" width="15" height="15" />
        <span v-if="timeframe == 'a'"
          >{{
            $t("product_detail_available_stores", {
              numberOfStores:
                totalInStockAvailability == 1
                  ? `${totalInStockAvailability} winkel tot 17u!`
                  : `${totalInStockAvailability} winkels tot 17u!`,
            })
          }}
        </span>
        <span v-else-if="timeframe == 'b'"
          >{{
            $t("product_detail_available_stores_special", {
              numberOfStores:
                totalInStockAvailability == 1
                  ? `${totalInStockAvailability} winkel`
                  : `${totalInStockAvailability} winkels`,
            })
          }}
        </span>
        <span v-else-if="timeframe == 'c'"
          >{{
            $t("product_detail_available_stores", {
              numberOfStores:
                totalInStockAvailability == 1
                  ? `${totalInStockAvailability} winkel tot 17u!`
                  : `${totalInStockAvailability} winkels tot 17u!`,
            })
          }}
        </span>
        <span v-else-if="timeframe == 'd'"
          >{{
            $t("product_detail_available_stores_special", {
              numberOfStores:
                totalInStockAvailability == 1
                  ? `${totalInStockAvailability} winkel`
                  : `${totalInStockAvailability} winkels`,
            })
          }}
        </span>
        <span v-else-if="timeframe == 'e'"
          >{{
            $t("product_detail_available_stores", {
              numberOfStores:
                totalInStockAvailability == 1
                  ? `${totalInStockAvailability} winkel tot 17u!`
                  : `${totalInStockAvailability} winkels tot 17u!`,
            })
          }}
        </span>
        <span v-else
          >{{
            $t("product_detail_available_stores_special", {
              numberOfStores:
                totalInStockAvailability == 1
                  ? `${totalInStockAvailability} winkel`
                  : `${totalInStockAvailability} winkels`,
            })
          }}
        </span>
      </label>
      <label v-else>
        <img src="@/base/assets/icons/pickup-icon.png" alt="" />{{
          $t("product_detail_available_stores_no_stock")
        }}
      </label>
    </b-button>
    <b-popover
      :target="cardType + 'store' + product.sku"
      triggers="focus"
      placement="bottom"
      custom-class="store-popup-wrap"
    >
      <div class="store-list-wrap">
        <div class="title">
          <label>{{ $t("product_detail_available_stores_stock") }}</label>
        </div>
        <div class="wrap">
          <ul class="available-store">
            <li
              v-for="(astore, index) in availability"
              :key="`astores-${index}`"
            >
              <span class="d-block" v-if="astore.store">{{
                astore.store.name
              }}</span>
            </li>
          </ul>
          <ul class="stock">
            <li
              v-for="(astore, index) in availability"
              :key="`sstores-${index}`"
            >
              <span v-if="astore.has_stock == true" class="in-stock"
                >{{ $t("in_stock") }}
                <span v-if="astore.qty_available >= 2">(2+)</span>
                <span v-else>(1)</span>
              </span>
              <span v-else class="not-in-stock">{{ $t("not_in_stock") }}</span>
            </li>
          </ul>
        </div>
        <small style="font-weight: 600">{{
          $t("product_detail_available_stores_stock_onepiece")
        }}</small>
      </div>
    </b-popover>
  </div>
</template>

<script>
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "PickupStores",
  props: ["product", "storeList", "totalInStock", "cardType"],
  data() {
    return {
      showDetail: false,
      timeframe: null,
    };
  },
  methods: {
    initialtimecheck() {
      if (this.checkblok("a") == true) {
        this.timeframe = "a";
      }
      if (this.checkblok("b") == true) {
        this.timeframe = "b";
      }
      if (this.checkblok("c") == true) {
        this.timeframe = "c";
      }
      if (this.checkblok("d") == true) {
        this.timeframe = "d";
      }
      if (this.checkblok("e") == true) {
        this.timeframe = "e";
      }
    },
    checkblok(block) {
      if (block == "a") {
        let start = new Date(`2021-12-30 17:00+0000`);
        let end = new Date(`2021-12-31 16:59+0000`);
        return start <= new Date() && new Date() <= end;
      }
      if (block == "b") {
        let start = new Date(`2021-12-31 17:00+0000`);
        let end = new Date(`2022-01-01 16:59+0000`);
        return start <= new Date() && new Date() <= end;
      }
      if (block == "c") {
        let start = new Date(`2022-01-01 17:00+0000`);
        let end = new Date(`2022-01-09 16:59+0000`);
        return start <= new Date() && new Date() <= end;
      }
      if (block == "d") {
        let start = new Date(`2022-01-09 17:00+0000`);
        let end = new Date(`2022-01-10 16:59+0000`);
        return start <= new Date() && new Date() <= end;
      }
      if (block == "e") {
        let start = new Date(`2022-01-10 17:00+0000`);
        let end = new Date(`2022-01-31 23:59+0000`);
        return start <= new Date() && new Date() <= end;
      }
    },
  },

  created() {
    this.initialtimecheck();
  },
  computed: {
    availability() {
      if (this.product.__typename == "SimpleProduct") {
        const locations = this.product.store_availability
          .map((item) => {
            if (item) {
              return {
                ...item,
                store: {
                  name: item.store.name.replace("EP:Tummers ", ""),
                },
              };
            }
          })
          .filter((item) => item);

        let newLocations = [];

        // let sortVal = ["roermond","Panningen","tegelen","echt","heythuysen","horst","weert","sittard"]

        let sortVal = config.pickupSortOptions;
        sortVal.forEach((item) => {
          const index = locations.findIndex(
            (location) =>
              location.store.name.toLowerCase() == item.toLowerCase()
          );
          if (index > -1) newLocations.push(locations.splice(index, 1)[0]);
        });

        newLocations = [...newLocations, ...locations];

        return newLocations;
      }
      if (this.product.__typename == "ConfigurableProduct") {
        Logger.debug(
          "availability",
          "ProductParameters",
          this.product.__typename
        )();
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            // return found.product.store_availability;
            return found.product.store_availability.map((item) => {
              if (item) {
                return {
                  ...item,
                  store: {
                    name: item.store.name.replace("EP:Tummers ", ""),
                  },
                };
              }
            });
          }
        }
      }

      return [];
    },
    totalInStockAvailability() {
      return this.product.store_availability.reduce((acc, item) => {
        if (item && item.has_stock) return (acc += 1);
        return acc;
      }, 0);
    },
  },
};
</script>

<style>
</style>
