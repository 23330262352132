<template>
  <div class="product-gallery">
    <b-row>
      <b-col col lg="2" md="2" cols="12">
        <div>
          <ClientOnly>
            <!-- <Lightbox
                :visible="visible"
                :imgs="selectedImage"
                :index="index"
                @hide="handleHide"
              ></Lightbox> -->

            <Lightbox
              :visible="visible"
              :imgs="lightBoxImages"
              :index="ligthBoxIndex"
              @hide="handleHide"
            ></Lightbox>

            <VueSlickCarousel
              v-bind="settings"
              class="carousel"
              :style="{ height: '372px' }"
            >
              <div
                v-for="(image, idx) of images"
                :key="idx"
                :id="`slide ${idx}`"
              >
                <b-link
                  class="slide-img-holder"
                  @click="clickSlideGallery(idx)"
                >
                  <div
                    class="slide-img"
                    :style="{ backgroundImage: `url(${image.url})` }"
                  ></div>
                </b-link>
              </div>
            </VueSlickCarousel>
          </ClientOnly>
          <div class="product-tag mobile-product-tag">
            <div class="tag" v-if="product.cashback_promotion">
              <span class="discount-txt">
                <span
                  v-if="
                    product.cashback_promotion.amount &&
                    product.cashback_promotion.amount.value &&
                    product.cashback_promotion.amount.value !== null
                  "
                >
                  &euro;{{ product.cashback_promotion.amount.value + ",-=" }}
                </span>
                <span v-if="product.cashback_promotion.name">
                  {{ product.cashback_promotion.name }}
                </span>
              </span>
            </div>
            <div
              class="product-tag-wrap"
              v-if="product.labels && product.labels.length"
            >
              <div
                class="product-tag-single"
                v-for="(label, idx) in product.labels"
                :key="idx"
              >
                <div v-if="label.product.type === 'image'" class="img-label">
                  <div
                    class="tag-label"
                    :class="label.product.position"
                    :style="
                      `background-image: url('` +
                      label.product.image_url +
                      `');${label.product.css}`
                    "
                  />
                </div>
                <div v-if="label.product.type === 'text'" class="text-label">
                  <div
                    class="tag-label"
                    :class="label.product.position"
                    :style="label.product.css"
                  >
                    {{ label.product.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col col lg="10" md="10" cols="12">
        <div class="big-img">
          <div class="product-tag desktop-product-tag">
            <div class="tag" v-if="product.cashback_promotion">
              <span class="discount-txt">
                <span
                  v-if="
                    product.cashback_promotion.amount &&
                    product.cashback_promotion.amount.value &&
                    product.cashback_promotion.amount.value !== null
                  "
                >
                  &euro;{{ product.cashback_promotion.amount.value + ",-=" }}
                </span>
                <span v-if="product.cashback_promotion.name">
                  {{ product.cashback_promotion.name }}
                </span>
              </span>
            </div>
            <div
              class="product-tag-wrap"
              v-if="product.labels && product.labels.length"
            >
              <div
                class="product-tag-single"
                v-for="(label, idx) in product.labels"
                :key="idx"
              >
                <!-- <div v-if="label.product.type === 'image'" class="img-label">
                  <div
                    class="tag-label"
                    :class="label.product.position"
                    :style="
                      `background-image: url('` +
                      label.product.image_url +
                      `');${label.product.css}`
                    "
                  />
                </div> -->
                <div v-if="label.product.type === 'image'" class="img-label">
                  <div
                    class="tag-label"
                    :class="label.product.position"
                    :style="
                      `width:${label.product.size}px;
                      height:${label.product.size}px;
                      background-image: url('` +
                      label.product.image_url +
                      `');
                      ${label.product.css}`
                    "
                  />
                </div>
                <div v-if="label.product.type === 'text'" class="text-label">
                  <div
                    class="tag-label"
                    :class="label.product.position"
                    :style="label.product.css"
                  >
                    {{ label.product.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="selected-image-holder d-none d-md-block"
            @click.prevent="showMultiple"
          >
            <!-- <b-link href="#" @click.stop="toggleIsInWishList">
              <i class="lnr lnr-heart"></i>
              <div class="heart-icon" v-if="isInWishList">
                <img src="../../../../assets/heart.png" alt="heart" />
              </div>
            </b-link> -->
            <div
              class="selected-image"
              :style="{ backgroundImage: `url(${selectedImage})` }"
            ></div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
//import { Carousel, Slide } from "vue-carousel";
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";

//import Lightbox from "vue-easy-lightbox";
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "ProductGallery",
  props: ["product", "images", "isInWishList"],
  components: {
    ClientOnly,
    // Carousel: () =>
    //   !isServer ? import("vue-carousel").then((m) => m.Carousel) : null,
    // Slide: () =>
    //   !isServer ? import("vue-carousel").then((m) => m.Slide) : null,
    VueSlickCarousel: () => (!isServer ? import("vue-slick-carousel") : null),
    Lightbox: () => (!isServer ? import("vue-easy-lightbox") : null),
  },
  data() {
    return {
      selectedImage: this.images[0].url,
      noOfSlidePerPage: 1,
      imgs: "",
      visible: false,
      index: 0,
      ligthBoxIndex: 0,
      slideNumber: 0,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: true,
        arrows: true,
        verticalSwiping: true,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              verticalSwiping: false,
              vertical: false,
            },
          },
        ],
      },
    };
  },
  computed: {
    lightBoxImages() {
      return this.images.map((image) => image.url);
    },
  },
  watch: {
    images: function (oldVal, newVal) {
      Logger.debug("newval[0]", "watch", newVal[0].url)();
      Logger.debug("this.selectedImage", "watch", this.selectedImage)();

      this.selectedImage = newVal[0].url;

      Logger.debug("this.selectedImage", "watch", this.selectedImage)();

      Logger.debug("oldVal", "watch", oldVal[0].url)();
      Logger.debug("newVal", "watch", newVal[0].url)();
      this.selectedImage = oldVal[0].url;
    },
  },
  mounted() {
    window.addEventListener("resize", this.setNoOfSlidePerPage);
    window.addEventListener("load", this.setNoOfSlidePerPage);
    this.setNoOfSlidePerPage();
    // this.sortImages();
  },
  destroyed() {
    Logger.debug("destroyed", "ProductGallery", "destroyed is called")();
    window.removeEventListener("resize", this.setNoOfSlidePerPage);
    window.removeEventListener("load", this.setNoOfSlidePerPage);
  },
  methods: {
    /*  sortImages(){
        console.log(this.images);
        return this.images.slice().sort(function(a, b) {
          return a.position - b.position;
        })
        console.log(this.images);

      },*/

    // clickSlideGallery(e) {
    //   this.changeSelectedImage(e);
    //   if (window.innerWidth < 768) {
    //     this.showMultiple();
    //   }
    // },
    // changeSelectedImage(e) {
    //   const slideNumber = e.target.parentElement.id.replace(/^\D+/g, "");
    //   this.slideNumber = +slideNumber;
    //   this.selectedImage = this.images[slideNumber].url;
    // },
    clickSlideGallery(id) {
      this.changeSelectedImage(id);
      if (window.innerWidth < 768) {
        this.showMultiple();
      }
    },
    changeSelectedImage(id) {
      this.selectedImage = this.images[id].url;
    },
    showMultiple() {
      this.imgs = this.images;
      this.index = this.slideNumber;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    setNoOfSlidePerPage() {
      this.noOfSlidePerPage = window.innerWidth < 768 ? 1 : 3;
    },
    toggleIsInWishList() {
      this.$emit("changeIsInWishList");
    },
  },
};
</script>

<style lang="scss"></style>
