var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumbs'),_c('section',{staticClass:"product product-section"},[_c('div',{staticClass:"go-back-btn container mt-3"},[_c('b-link',{nativeOn:{"click":function($event){return _vm.$router.back()}}},[_c('b-icon',{attrs:{"icon":"chevron-left"}}),_vm._v(_vm._s(_vm.$t("go_back")))],1)],1),(_vm.product)?_c('div',{staticClass:"container"},[_c('b-row',{staticClass:"pb-md-40 product-details"},[_c('b-col',{attrs:{"col":"","lg":"8","md":"12","cols":"12"}},[(_vm.product.media_gallery.length > 0)?_c('ProductGallery',{attrs:{"product":_vm.product,"images":_vm.product.media_gallery,"isInWishList":_vm.$store.getters['user/isProductInWishlist'](_vm.product.sku)},on:{"changeIsInWishList":_vm.UpdateIsInWishList}}):_vm._e(),(_vm.product.media_gallery.length == 0)?_c('ProductGallery',{attrs:{"product":_vm.product,"images":[_vm.product.image],"isInWishList":_vm.$store.getters['user/isProductInWishlist'](_vm.product.sku)},on:{"changeIsInWishList":_vm.UpdateIsInWishList}}):_vm._e()],1),_c('b-col',{attrs:{"col":"","lg":"4","md":"12","cols":"12"}},[_c('div',[_c('span',{staticClass:"product--name"},[_vm._v(_vm._s(_vm.product.name))])]),_c('div',{staticClass:"rating-wrap"},[_c('div',{attrs:{"data-bv-show":"rating_summary","data-bv-product-id":_vm.product.sku}}),(_vm.energyClass)?_c('div',{staticClass:"energy-class"},[_c('span',{class:{
                  'energy-class-a':
                    _vm.energyClass.value[0].toUpperCase() === 'A',
                  'energy-class-b':
                    _vm.energyClass.value[0].toUpperCase() === 'B',
                  'energy-class-c':
                    _vm.energyClass.value[0].toUpperCase() === 'C',
                  'energy-class-d':
                    _vm.energyClass.value[0].toUpperCase() === 'D',
                  'energy-class-e':
                    _vm.energyClass.value[0].toUpperCase() === 'E',
                  'energy-class-f':
                    _vm.energyClass.value[0].toUpperCase() === 'F',
                  'energy-class-g':
                    _vm.energyClass.value[0].toUpperCase() === 'G',
                }},[_vm._v(_vm._s(_vm.energyClass.value))])]):_vm._e()]),(_vm.product.__typename != 'BundleProduct')?_c('ProductParameters',{attrs:{"isInWishList":_vm.$store.getters['user/isProductInWishlist'](_vm.product.sku)},on:{"changeIsInWishList":_vm.UpdateIsInWishList}}):_vm._e(),(_vm.product.__typename == 'BundleProduct')?_c('ProductParametersBundle',{attrs:{"isInWishList":false}}):_vm._e(),_c('ul',{staticClass:"note in-detail"},[(typeof _vm.UspContent == 'object')?_c('cmsBlockSimple',{attrs:{"identifier":_vm.UspContent.identifier}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"product-description py-5"},[_c('b-row',{},[_c('b-col',{attrs:{"col":"","lg":"12"}},[_c('DescriptionTab')],1)],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }