<template>
  <div>
    <!-- AddToCart Modal -->
    <b-modal
      id="addToCart"
      title
      size="lg"
      v-model="addToCartPopup"
      :hide-footer="true"
      dialog-class="cart-modal"
      :centered="true"
    >
      <b-row class="add-to-cart-popup">
        <b-col col lg="9">
          <div class="title mb-3">
            <h4>{{ $t("cart_popup_title") }}</h4>
            <div class="sub-title">{{ $t("cart_popup_subtitle") }}</div>
          </div>
          <div class="addToCart-wrap mb-4">
            <div class="cart-product-details">
              <div class="image">
                <img :src="product.thumbnail.url" />
                <div class="cart-product-name">{{ product.name }}</div>
              </div>
              <div class="cart-product-price">
                {{
                  formatPrice(
                    priceRange.maximum_price.final_price.value +
                      selectedExtraPrice
                  )
                }}
              </div>
            </div>
          </div>
          <div class="cart-buttons-wrap d-flex justify-content-between">
            <button class="btn btn-primary" @click="clickOk">
              Bekijk winkelwagen
            </button>
            <button class="btn btn-gray" @click="clickCancel">
              Verder winkelen
            </button>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="person-img">
            <img src="@/base/assets/add_to_cart_popup.png" alt="" />
          </div>
        </b-col>
        <b-col col lg="12" v-if="related.length">
          <div class="cart-slider">
            <div class="title">
              <h4>Nog wat accessoires meepakken?</h4>
            </div>
            <div class="mt-4">
              <ProductSlider
                :productList="related"
                :showAddToCart="true"
                :productToShow="4"
                :rows="1"
                :infinite="true"
                :arrows="false"
                :dots="true"
                :sliderResponsive="[
                  {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      arrows: true,
                    },
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      arrows: true,
                    },
                  },
                  {
                    breakpoint: 540,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      arrows: true,
                    },
                  },
                ]"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <!-- AddToCompare Modal -->
    <b-modal
      id="addToCompare"
      title
      size="lg"
      content-class="product-popup product-compare-popup"
      dialog-class="cart-modal"
      centered
      v-model="addToComparePopup"
      :hide-footer="true"
    >
      <b-row class="add-to-cart-popup">
        <b-col col lg="9">
          <div class="title mb-3">
            <h4>{{ $t("compare_popup_title") }}</h4>
          </div>
          <div class="addToCart-wrap mb-4">
            <div class="cart-product-details">
              <div class="image">
                <img :src="product.thumbnail.url" />
                <div class="cart-product-name">{{ product.name }}</div>
              </div>
              <div class="cart-product-price">
                {{
                  formatPrice(
                    priceRange.maximum_price.final_price.value +
                      selectedExtraPrice
                  )
                }}
              </div>
            </div>
          </div>
          <div class="cart-buttons-wrap d-flex justify-content-between">
            <button class="btn btn-primary" @click="clickOkCompare">
              {{ $t("compare") }}
            </button>
            <button class="btn btn-gray" @click="clickCancelCompare">
              {{ $t("continue_shoping") }}
            </button>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="person-img">
            <img src="@/base/assets/add_to_cart_popup.png" alt="" />
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <!-- ConfigurableProduct -->
    <div v-if="product.__typename == 'ConfigurableProduct'">
      <div v-for="parameter of options" :key="parameter.id">
        <b-modal
          v-if="parameter.attribute_code == 'fkv_maten'"
          id="modal-scrollable"
          scrollable
          :title="$t('sizechart')"
        >
          <div class="text">
            <p>{{ $t("sizechart_warning") }}</p>
          </div>
          <b-table
            class="size-table"
            responsive
            :items="tableSize"
            :fields="fields"
          ></b-table>
        </b-modal>

        <div class="size-selection mb-3">
          <div class="selection-category d-flex justify-content-between mb-2">
            <strong class="select-size">{{ parameter.label }}</strong>
            <b-link
              v-if="parameter.attribute_code == 'fkv_maten'"
              v-b-modal.modal-scrollable
              class="size-info ml-10 green-text"
            >
              <strong>{{ $t("view_sizechart") }}</strong>
            </b-link>
          </div>
          <div
            class="select-wrapper"
            v-if="parameter.attribute_code == 'fkv_maten'"
          >
            <div
              v-for="opt of parameter.values"
              :key="opt.value_index"
              @click="selectOption(parameter.index, opt.value_index)"
              :class="{ active: priceSize === opt.value_index }"
            >
              <span>{{ opt.label }}</span>
              <!-- <span>&euro; {{ opt.price }}</span> -->
            </div>
          </div>
        </div>

        <div
          class="size-selection"
          v-if="
            parameter.attribute_code == 'fkv_maten' &&
            productConfig.size_chart != null
          "
        >
          <div class="selection-category d-flex justify-content-between">
            <span class="select-size">
              {{ $t("which_measure_in_cupboard") }}
            </span>
          </div>
          <div
            class="select-wrapper"
            v-if="parameter.attribute_code == 'fkv_maten'"
          >
            <ul class="size-selection--available-sizes">
              <li v-for="opt of customMeasures" :key="opt.value_index">
                <label class="custom-radio-input">
                  <input
                    type="radio"
                    v-model="priceSize"
                    :id="`${parameter.attribute_code}-${opt.value_index}`"
                    :name="`${parameter.attribute_code}-select`"
                    :value="opt.value_index"
                    v-on:click="processOption(parameter.index, opt.value_index)"
                  />
                  <span class="custom-radio-input--btn font-weight-normal">
                    {{ opt.label }}
                  </span>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div
          v-if="parameter.attribute_code != 'fkv_maten'"
          class="size-selection-radio"
        >
          <ul class="size-selection--available-sizes">
            <li v-for="opt of parameter.values" :key="opt.value_index">
              <label class="custom-radio-input">
                <input
                  type="radio"
                  :id="`${parameter.attribute_code}-${opt.value_index}`"
                  :name="`${parameter.attribute_code}-select`"
                  :value="opt.value_index"
                  v-on:click="processOption(parameter.index, opt.value_index)"
                />
                <span class="custom-radio-input--btn font-weight-normal">
                  {{ opt.label }}
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- postal-code -->
    <service-postcode-validation
      :product="product"
      @add-services-parent="getServicesFromParent"
    ></service-postcode-validation>
    <!-- price-range -->
    <div class="d-flex justify-content-between">
      <div
        v-if="
          priceRange.minimum_price.final_price.value !=
          priceRange.maximum_price.final_price.value
        "
        class="top-wrap"
      >
        <div
          v-if="
            priceRange.maximum_price.regular_price.value >
            priceRange.minimum_price.final_price.value
          "
          class="d-flex align-items-end price-selection"
        >
          <span class="normal-price">
            <!-- <span>{{ $t("advice_price") }}:</span> -->
            <span>
              {{
                formatPrice(
                  priceRange.maximum_price.regular_price.value +
                    selectedExtraPrice
                )
              }}
            </span>
          </span>
        </div>
        <div class="d-flex align-items-end price-selection">
          <span class="currentPrice">
            {{
              formatPrice(
                priceRange.minimum_price.final_price.value + selectedExtraPrice
              )
            }}
            -
            {{
              formatPrice(
                priceRange.maximum_price.final_price.value + selectedExtraPrice
              )
            }}
          </span>
          <!-- <span
            class="discount-comment"
            v-if="priceRange.minimum_price.discount.percent_off > 0"
            >{{ $t("discount") }}
            {{
              priceRange.minimum_price.discount.percent_off.toFixed(0)
            }}%</span
          >
          <span
            class="discount-comment2"
            v-if="
              priceRange.maximum_price.discount.percent_off >
              priceRange.minimum_price.discount.percent_off
            "
            >{{ $t("to_discount") }}
            {{
              priceRange.maximum_price.discount.percent_off.toFixed(0)
            }}%</span
          >-->
        </div>
      </div>

      <div
        v-if="
          priceRange.minimum_price.final_price.value ==
          priceRange.maximum_price.final_price.value
        "
        class="top-wrap"
      >
        <!-- <span
          class="normal-price d-block"
          v-if="product.manufacturer_price.price.value > 0"
          >{{ $t("advice_price") }}: 
          <span>{{
            formatPrice(product.manufacturer_price.price.value+selectedExtraPrice)
          }}</span>
        </span>-->

        <!-- v-if="
          priceRange.minimum_price.regular_price.value >
          priceRange.minimum_price.final_price.value
        "-->
        <div
          v-if="priceRange.minimum_price.discount.percent_off >= 5"
          class="d-flex align-items-end price-selection"
        >
          <span class="normal-price">
            <span>{{ $t("advice_price") }}:</span>
            <span class="original-price">
              <span class="price-stroke"></span>
              {{
                formatPrice(
                  priceRange.minimum_price.regular_price.value +
                    selectedExtraPrice
                )
              }}
            </span>
          </span>
        </div>
        <div class="d-flex align-items-end price-selection">
          <span class="currentPrice">
            <div class="exchange-price" v-if="isProductInTrade">
              {{ $t("price_after_exchange") }}
            </div>
            {{
              formatPrice(
                priceRange.minimum_price.final_price.value + selectedExtraPrice
              )
            }}
          </span>

          <!-- Discount -->
          <!-- <span
            class="discount-comment"
            v-if="priceRange.minimum_price.discount.percent_off > 0"
            >{{ $t("discount") }}
            {{
              priceRange.minimum_price.discount.percent_off.toFixed(0)
            }}%</span
          >-->
        </div>
      </div>

      <div v-if="product.brand != null" class="product-logo">
        <b-link :href="`/` + product.brand.urlKey">
          <img :src="product.brand.logo" :alt="product.brand.title" />
        </b-link>
      </div>
    </div>

    <DeliveryTime :data="product.DeliveryTime" />

    <PickupStores :product="product" :cardType="'pickup'" />

    <div class="add-to-cart">
      <button
        v-on:click="addToCart"
        v-bind:disabled="btnDisable"
        :style="buttonClass"
        class="btn product-parameter-submit-btn btn-primary web-btn"
      >
        {{ $t("order_now") }}
        <i class="fas fa-shopping-cart"></i>
      </button>
      <div class="mob-ordernow-btn">
        <button
          v-on:click="addToCart"
          v-bind:disabled="btnDisable"
          :style="buttonClass"
          class="btn product-parameter-submit-btn btn-primary"
        >
          {{ $t("order_now") }}
          <i class="fas fa-shopping-cart"></i>
        </button>
      </div>
    </div>

    <!-- actions -->
    <div class="product-extra list-view my-3">
      <div
        class="add-wish"
        :class="{ 'in-wishlist': isInWishList }"
        @click="toggleWishItem"
      >
        <i class="fas fa-heart"></i>
        <label>{{ $t("wishlist") }}</label>
      </div>
      <div class="add-compare">
        <b-form-checkbox
          id="compare"
          v-model="compareStatus"
          label-for="compare"
          >{{ $t("compare") }}</b-form-checkbox
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { mapGetters } from "vuex";
import { Logger } from "@storefront/core/lib/logger";
import config from "@config";

import ProductSlider from "@/base/core/components/products/product-detail/ProductSlider.vue";
import PickupStores from "@/base/core/components/products/product-detail/PickupStores.vue";
import DeliveryTime from "@/base/core/components/products/product-detail/DeliveryTime.vue";
import ServicePostcodeValidation from "@/base/core/components/products/product-detail/ServicePostcodeValidation.vue";

export default {
  name: "ProductParameters",
  components: {
    ServicePostcodeValidation,
    [Select.name]: Select,
    [Option.name]: Option,
    ProductSlider,
    PickupStores,
    DeliveryTime,
  },
  props: {
    parameters: { type: Object },
    isInWishList: { type: Boolean },
  },
  async created() {
    // console.log("All Products", this.product);
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      productConfig: "product/getCurrentConfig",
      options: "product/getCurrentProductOptions",
      customMeasures: "product1/getCurrentCustomMeasures",
      related: "product/getRelated",
      compareList: "product/getProductCompare",
    }),
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    isProductInTrade() {
      const catId = config.tradeInPromotion;
      if (catId) {
        return this.product.categories.some((category) => category.id == catId);
      }
      return false;
    },
    isProductInCompare() {
      return !!this.compareList.find(
        (product) => product.sku === this.product.sku
      );
    },
    compareStatus: {
      get() {
        return this.isProductInCompare;
      },
      set(value) {
        if (value && !this.isProductInCompare) this.addToCompare();
        else this.removeFromCompare();
      },
    },
    btnDisable() {
      if (this.product.__typename == "ConfigurableProduct") {
        const retval = this.$store.getters["product/getCurrentChildSku"];
        if (retval != null) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    buttonClass() {
      if (this.btnDisable == true) {
        return "cursor: not-allowed;";
      } else {
        return "";
      }
    },
    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
    finalPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.final_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.final_price.value !=
          this.product.price_range.maximum_price.final_price.value
        ) {
          return (
            this.product.price_range.minimum_price.final_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.final_price.value.toFixed(2)
          );
        }
        return this.product.price_range.minimum_price.final_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.final_price.value !=
        this.product.price_range.maximum_price.final_price.value
      ) {
        return (
          this.product.price_range.minimum_price.final_price.value.toFixed(2) +
          " - " +
          this.product.price_range.maximum_price.final_price.value.toFixed(2)
        );
      }
      return this.product.price_range.minimum_price.final_price.value.toFixed(
        2
      );
    },
    priceRange() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range;
          }
        }
        return this.product.price_range;
      }
      return this.product.price_range;
    },
    getGTMItems() {
      let items = {
        item_name: this.product.name,
        item_id: this.product.sku,
        quantity: this.quantity,
        price: this.finalPrice,
      };
      if (this.finalPrice != this.regularPrice) {
        items["discount"] = this.regularPrice - this.finalPrice;
      }
      return items;
    },
  },

  data: () => ({
    quantity: 1,
    postalCode: "",
    popup: false,
    addToCartPopup: false,
    addToComparePopup: false,
    selectedOptions: [],
    selectedExtraPrice: 0,
    priceSize: "",
    tableSize: [
      {
        name: "Billy / BHE",
        sizeS: "49.5 - 54 cm",
        sizeM: "55 - 58 cm",
        sizeL: "n/a",
      },
      {
        name: "Metro / BHE -55",
        sizeS: "n/a",
        sizeM: "55 - 58 cm",
        sizeL: "56 - 61 cm",
      },
      {
        name: "Indra / BHE -56",
        sizeS: "n/a",
        sizeM: "55 - 58 cm",
        sizeL: "58 - 62 cm",
      },
    ],
    fields: [
      {
        key: "name",
        label: "",
      },
      {
        key: "sizeS",
        label: "S",
      },
      {
        key: "sizeM",
        label: "M",
      },
      {
        key: "sizeL",
        label: "L",
      },
    ],
  }),

  methods: {
    toggleWishItem() {
      this.$emit("changeIsInWishList");
    },
    clickOk() {
      this.addToCartPopup = false;
      this.$router.push("/checkout/cart");
    },
    clickCancel() {
      this.addToCartPopup = false;
      window.scrollTo(0, 0);
    },
    clickOkCompare() {
      this.addToComparePopup = false;
      this.$router.push("/compare");
    },
    clickCancelCompare() {
      this.addToComparePopup = false;
      window.scrollTo(0, 0);
    },
    quantityUp() {
      this.quantity++;
    },
    quantityDown() {
      if (this.quantity > 1) this.quantity--;
    },
    selectOption(index, value) {
      this.priceSize = value;
      this.processOption(index, value);
    },
    processOption(index, value) {
      Logger.debug("processOption", "ProductParameters", {
        index: index,
        value: value,
      })();
      this.$store.commit("product/setOptionValue", {
        index: index,
        value: value,
      });
    },
    async addToCart() {
      switch (this.product.__typename) {
        case "SimpleProduct": {
          let items =
            "[{ quantity:" +
            this.quantity +
            ' sku: "' +
            this.product.sku +
            '" ';
          let custOptions = "";
          //this.product.options;  product.options

          console.log("options???");
          console.log(this.selectedOptions);

          if (this.selectedOptions.length > 0) {
            custOptions = " selected_options: [";
            this.selectedOptions.forEach((option) => {
              custOptions = custOptions + '"' + option.uid + '",';
            });
            custOptions = custOptions + "]";
          }

          items = items + custOptions + "}]";
          /*
          let item =
            '{data: {sku: "' +
            this.product.sku +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let custOptions =""
          if (this.selectedOptions.length>0){
            custOptions =",customizable_options: [";
            this.selectedOptions.forEach.forEach((option, idx) => {

            });
            custOptions = custOptions + "]";
          }
          item = item + custOptions;
          */
          let retval = await this.$store.dispatch("cart/addProductToCart", {
            items: items,
          });
          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              if (typeof window.fbq === "function") {
                window.fbq("track", "AddToCart");
              }
              if (!this.$gtm.uaDisabled) {
                window.dataLayer.push({
                  event: "eec.add",
                  ecommerce: {
                    add: {
                      products: [
                        {
                          name: this.product.name,
                          id: this.product.sku,
                          quantity: this.quantity,
                        },
                      ],
                    },
                  },
                });
              }
              if (this.$gtm.ga4Enabled) {
                window.dataLayer.push({
                  event: "add_to_cart",
                  ecommerce: {
                    add: {
                      currency: "EUR",
                      value: this.finalPrice,
                      items: [this.getGTMItems],
                    },
                  },
                });
              }
            }
            this.addToCartPopup = true;
            // this.$bvModal.show("addToCart");
          }
          break;
        }
        case "ConfigurableProduct": {
          const item =
            '{ parent_sku: "' +
            this.product.sku +
            '" data: {sku: "' +
            this.$store.getters["product/getCurrentChildSku"] +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              if (typeof window.fbq === "function") {
                window.fbq("track", "AddToCart");
              }
              Logger.debug("Watch Product", "product.vue", "CALLED")();
              window.dataLayer.push({
                event: "eec.add",
                ecommerce: {
                  add: {
                    products: [
                      {
                        name: this.product.name,
                        id: this.product.sku,
                        quantity: this.quantity,
                      },
                    ],
                  },
                },
              });
            }
            if (this.$gtm.ga4Enabled) {
              //GA4 event
              window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                  add: {
                    currency: "EUR",
                    value: this.finalPrice,
                    items: [this.getGTMItems],
                  },
                },
              });
            }
            this.addToCartPopup = true;
            // this.$bvModal.show("addToCart");
          }
          break;
        }
        default:
          break;
      }
    },
    getServicesFromParent(payload) {
      console.log(payload);

      this.selectedOptions = payload.services;
      this.selectedExtraPrice = payload.selectedExtraPrice;
      this.selectedExtraPrice = payload.services.reduce((acc, option) => {
        return acc + option.price;
      }, 0);
    },
    async addToCompare() {
      let retval = await this.$store.dispatch("product/addCompareProduct", {
        sku: this.product.sku,
      });
      Logger.debug("retval", "addToCompare", retval)();
      if (retval == false) {
        const msg = {
          type: "danger",
          title: "",
          text: "Unable to add",
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      } else {
        this.addToComparePopup = true;
      }
    },
    async removeFromCompare() {
      let retval = await this.$store.commit(
        "product/removeProductFromCompare",
        this.product.sku
      );
      Logger.debug("retval", "removeFromCompare", retval)();
      if (retval === false) {
        const msg = {
          type: "danger",
          title: "",
          text: "something went wrong",
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },

    formatPrice(price) {
      if (price % 1 === 0) {
        return "€" + price + ",-";
      }
      return "€" + price.toFixed(2).replace(".", ",");
    },
  },
};
</script>

<style lang="scss" scoped></style>
