<template>
  <div
    class="postalcode"
    v-if="
      currentProperties &&
      currentProperties.options &&
      currentProperties.options.every((option) => option.value)
    "
  >
    <div class="title">
      <b-modal
        :id="'modal'"
        :visible="showServiceInfo === true"
        @hidden="showServiceInfo = null"
        hide-header
        hide-footer
        centered
      >
        <div class="postalmodal">
          <div class="close-modal" @click="closeServiceModal()">
            <span><i class="fa fa-times"></i></span>
          </div>
          <div
            class="spectmodal-content fancy-scroll"
            v-if="serviceInfoContent === null"
          >
            <label>Postcodecheck</label>
            <p>
              In onze eigen regio bezorgen wij met onze eigen bezorgdienst.
              Controleer je postcode om te zien of je gebruik kan maken van onze
              extra service.
            </p>
            <div class="postal-input">
              <b-form-input
                id="postalcode"
                type="text"
                v-model="postalCode"
                aria-describedby="postalcode-feedback"
                placeholder="1234AB"
              ></b-form-input>
              <b-btn @click="verifyPostalcode" variant="secondary"
                >Controleer postcode</b-btn
              >
            </div>
            <div class="postal-validate-msg">
              <p class="valid" v-if="isPostalCodeValid == true">
                Je kunt gebruik maken van onze extra services en deze zijn
                toegevoegd aan uw product.
              </p>
              <p class="not-valid" v-if="isPostalCodeValid == false">
                De extra diensten zijn helaas niet beschikbaar in jouw regio.
                Wel wordt dit product tot aan de deur bezorgd en kan de oude
                retour worden genomen mits deze klaar staat. De levertijd buiten
                onze regio is 2-4 werkdagen.
              </p>
            </div>
          </div>
          <div v-html="this.serviceInfoContent"></div>
          <div v-if="showCloseButton">
            <b-btn @click="closeServiceModal">Doorgaan</b-btn>
          </div>
        </div>
      </b-modal>
    </div>
    <div
      v-if="
        currentProperties &&
        currentProperties.options &&
        currentProperties.options.length
      "
      class="postal-custom-option"
    >
      <div>
        <b-form-group>
          <b-form-checkbox
            v-for="option in currentProperties.options[0].value"
            v-model="selectedOptions"
            :key="option.option_type_id"
            :value="option"
            >{{ option.title }} +
            {{ formatPrice(option.price) }}
            <div
              class="cursor-pointer fa-pull-right pl-2"
              @click.stop.prevent="showServiceModalPopup(option.sku)"
            >
              <i class="fas fa-info-circle" :data-sku="option.sku"></i>
            </div>
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import service_json from "../../../../i18n/services.json";

export default {
  name: "ServicePostcodeValidation",
  props: {
    product: { type: Object, required: false },
    cartItem: { type: Object, required: false },
  },
  data: () => ({
    postalCode: "",
    isPostalCodeValid: null,
    selectedOptions: [],
    selectedExtraPrice: 0,
    showServiceInfo: null,
    serviceInfoContent: null,
    services: service_json,
    doEmit: true,
    currentProperties: {},
    isCart: false,
    showCloseButton: false,
    selectedServices: [],
  }),
  watch: {
    selectedOptions(value) {
      this.selectedServices = value;
      if (value.length != 0 && !this.isPostalCodeValid) {
        this.isPostalCodeValid = null;
        this.showServiceInfo = true;
      }
      this.selectedExtraPrice = value.reduce((acc, option) => {
        return acc + option.price;
      }, 0);
      if (this.doEmit) {
        this.emitServicesEventToParent();
      }
      this.doEmit = true;
    },
    currentProperties() {
      if (this.currentProperties.options) {
        this.currentProperties.options.forEach((option) => {
          if (option.title == "Tummers Diensten") {
            option.value.forEach((value) => {
              if (
                this.selectedCartItemOptions.indexOf(
                  value.option_type_id.toString()
                ) !== -1
              ) {
                this.doEmit = false;
                this.selectedOptions.push(value);
                this.preSelectedOptions += 1;
              }
            });
          }
        });
      }
    },
  },
  methods: {
    formatPrice(price) {
      return this.$helpers.formatCurrency(price).replace(/\s/g, "");
    },
    closeServiceModal() {
      this.showServiceInfo = false;
      this.serviceInfoContent = null;
      if (!this.isPostalCodeValid) {
        this.selectedOptions = [];
        this.$store.commit("product1/setServicePostcode", "");
        this.isPostalCodeValid = null;
      } else {
        this.$store.commit("product1/setServicePostcode", this.postalCode);
        this.emitServicesEventToParent();
      }
    },
    lookupservice(servicesku) {
      if (!this.services[servicesku])
        return "Helaas is er voor deze serviceoptie nog geen omschrijving beschikbaar.";
      return this.services[servicesku];
    },

    showServiceModalPopup(servicesku) {
      this.showServiceInfo = true;
      this.serviceInfoContent = this.lookupservice(servicesku);
    },
    emitServicesEventToParent() {
      if (this.isPostalCodeValid) {
        this.$emit("add-services-parent", {
          services: this.selectedServices,
          selectedExtraPrice: this.selectedExtraPrice,
          cartItem: this.cartItem ? this.cartItem : {},
          productType: this.currentProperties.__typename,
        });
      }
    },
    async verifyPostalcode() {
      const response = await this.$store.dispatch(
        "product1/checkExtraService",
        this.postalCode
      );
      if (response && response.is_available) {
        this.isPostalCodeValid = true;
        this.$store.commit("product1/setServicePostcode", this.postalCode);
        this.showCloseButton = true;
      } else {
        this.isPostalCodeValid = false;
        this.$store.commit("product1/setServicePostcode", "");
        this.selectedOptions = [];
      }
    },
    async getProduct() {
      if (this.cartItem) {
        this.isCart = true;
        if (this.cartItem.product && this.cartItem.product.sku) {
          let product = await this.$store.dispatch("product/loadWithSku", {
            sku: this.cartItem.product.sku,
          });
          this.currentProperties = product;
        }
      } else {
        this.currentProperties = this.product;
      }
    },
  },
  computed: {
    selectedCartItemOptions() {
      var options = [];
      if (this.cartItem) {
        this.cartItem.customizable_options.forEach((option) => {
          if (option.values) {
            option.values.forEach((value) => {
              options.push(value.value);
            });
          }
        });
      }
      return options;
    },
  },
  mounted() {
    this.getProduct();
    if (this.$store.getters["cart/getItemsTotalQuantity"] == 0) {
      this.$store.commit("product1/setServicePostcode", "");
    }
  },
  created() {
    var ServicePostalCode = this.$store.getters["product1/getServicePostcode"];
    if (ServicePostalCode) {
      this.isPostalCodeValid = true;
    }
  },
};
</script>

<style scoped>
</style>
