<template>
  <div
    v-if="data && !!data.long"
    class="delivery-info"
    :style="`color: ${data.color}`"
  >
    <div class="delivery-infoWrap">
      <img v-if="data.icon" :src="`${data.icon}`" alt="" />
      <label>{{ data.long }} </label>
    </div>
  </div>
</template>

<script>
import config from "@config";

export default {
  name: "DeliveryTime",
  props: ["data"],
  data: () => ({
    app_url: config.app_url,
  }),
};
</script>

<style lang="scss" scoped>
.delivery-infoWrap {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  width: 100%;
  img {
    max-height: 30px;
    margin-right: 10px;
  }
}
</style>
